<template>
  <Modal
    title="编辑个人信息"
    v-model="p_visible"
    @on-ok="ok"
    @on-cancel="cancel">
    <div class="user-edit-contain">
      <Form :label-width="85" :model="MyUser">
        <Form-item label="所属部门：">
          <Select disabled v-model="unit">
            <Option value="1">{{MyUser.unit}}</Option>
          </Select>
        </Form-item>
        <Form-item label="担任职务：">
          <Select disabled v-model="position">
            <Option value="1">{{MyUser.position}}</Option>
          </Select>
        </Form-item>
        <Form-item label="姓名：">
          <Input disabled v-model="name"></Input>
        </Form-item>
        <Form-item label="性别：">
          <Radio-group v-model="sex">
            <Radio label="0">男</Radio>
            <Radio label="1">女</Radio>
          </Radio-group>
        </Form-item>
        <Form-item label="生日：">
          <Date-picker :value="date" type="date" placeholder="选择日期" @on-change="handleDate" @on-clear="handleClear"></Date-picker>
        </Form-item>
        <Form-item label="手机号：">
          <Input v-model="phoneNumber"></Input>
        </Form-item>
        <Form-item label="头像：">
          <div class="user-info">
            <template v-if="imgShow">
              <img :src="avatar" alt="" class="user-img" v-if="avatar">
              <img src="@/assets/img/bg_avatar.png" alt="" class="user-img" v-else>
            </template>
            <template v-else>
              <div class="user-img">
                <Progress :percent="imgList.percentage" hide-info></Progress>
              </div>
            </template>
            <Upload
              ref="upload"
              action="/api/staff/photo_upload"
              :format="['jpg', 'jpeg', 'png']"
              :show-upload-list="false"
              :before-upload="handleBeforeUpload"
              :on-success="handleUploadSuccess"
              :on-format-error="handleFormatError"
              name = "photo"
              style="display: inline-block;width:80px;">
              <a href="javascript:;" class="change-avatar">更换头像</a>
            </Upload>
          </div>
        </Form-item>
      </Form>
    </div>
    <div slot="footer" class="tac">
      <Button @click="cancel">取消</Button>
      <Button @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script type="text/ecmascript-6">
import propsync from '@/mixins/propsync';

export default {
	mixins: [propsync],
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		MyUser: {
			type: Object,
		},
	},
	data() {
		return {
			position: '1',
			unit: '1',
			phoneNumber: '',
			sex: '',
			date: '',
			avatar: '',
			id: '',
			imgList: '',
			imgShow: true,
			name: '',
		};
	},
	methods: {
		initData() {
			this.avatar = this.MyUser.avatar;
			this.phoneNumber = this.MyUser.phone;
			this.sex = this.MyUser.sex;
			this.date = this.MyUser.date_of_birth;
			this.name = this.MyUser.name;
		},
		ok() {
			if (this.validateData()) {
				const user = {
					sex: this.sex,
					phont: this.phone,
					date_of_birth: this.date,
					avatar: this.id,
					name: this.name,
				};
				this.$emit('on-ok', { ...this.MyUser, ...user });
			}
		},
		cancel() {
			this.initData();
			this.$emit('on-cancel');
		},
		handleChange(page, index) {
			this.p_visible = index;
		},
		handleClear() {
			this.date = '';
		},
		handleDate(value) {
			this.date = value;
		},
		validateData() {
			let reg = /^(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
			if (!this.date) {
				this.$Message.warning('请选择出生日期');
				return false;
			} else if (!this.phoneNumber) {
				this.$Message.warning('请输入电话号码');
				return false;
			} else if (!reg.test(this.phoneNumber)) {
				this.$Message.warning('请输入正确的手机号码');
				return false;
			} else {
				return true;
			}
		},
		handleUploadSuccess(response, file) {
			this.imgList = file;
			if (file.status === 'finished') {
				this.imgShow = true;
			} else {
				this.imgShow = false;
			}
			this.avatar = response.data.photo;
			this.id = response.data.id;
		},
		handleFormatError(file) {
			this.$Message.warning('文件 ' + file.name + ' 格式不正确，请上传 jpg 或 png 格式的图片。');
			this.imgShow = true;
		},
		handleBeforeUpload() {
			this.imgShow = false;
		},
	},
	created() {
		this.initData();
	},
};
</script>
<style lang="css" scoped>
.user-edit-contain .user-img {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border: 5px solid #f1ce7e;
  border-radius: 100%;
  float: left;
}
.user-edit-contain .change-avatar {
  color: #51c78a;
  text-decoration: underline;
  padding-left: 20px;
  line-height: 90px;
}
</style>
