<template>
  <div class="user-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>个人信息</Breadcrumb-item>
      </Breadcrumb>
    </v-title>

    <div class="content">
      <Card>
        <p slot="title" class="user-text">个人信息</p>
        <div class="user-oprate" slot="extra">
          <a href="javascript:;" class="user-edit" @click="handleEdit">
            <Icon type="md-create" color="#fdad00" style="font-size: 20px;margin-right: 5px;"></Icon>编辑
          </a>
          <a href="javascript:;" class="user-adjust" @click="handleChangePSW">
            <Icon type="md-alert" color="#fdad00" style="font-size: 20px;margin-right: 5px;"></Icon>修改密码
          </a>
        </div>

        <div class="user-info-content">
          <div class="user-info-box clearfix">
            <div class="user-label">
              <span>所属部门</span>
            </div>
            <div class="user-info">
              <span>{{MyUser.unit}}</span>
            </div>
          </div>
          <div class="user-info-box clearfix">
            <div class="user-label">
              <span>担任职务</span>
            </div>
            <div class="user-info">
              <span>{{MyUser.position}}</span>
            </div>
          </div>
          <div class="user-info-box clearfix">
            <div class="user-label">
              <span>姓名</span>
            </div>
            <div class="user-info">
              <span>{{MyUser.name}}</span>
            </div>
          </div>
          <div class="user-info-box clearfix">
            <div class="user-label">
              <span>性别</span>
            </div>
            <div class="user-info">
              <span>{{MyUser.sex | sex}}</span>
            </div>
          </div>
          <div class="user-info-box clearfix">
            <div class="user-label">
              <span>生日</span>
            </div>
            <div class="user-info">
              <span>{{MyUser.date_of_birth}}</span>
            </div>
          </div>
          <div class="user-info-box clearfix">
            <div class="user-label">
              <span>手机号</span>
            </div>
            <div class="user-info">
              <span>{{MyUser.phone}}</span>
            </div>
          </div>
          <div class="user-info-box user-avater clearfix">
            <div class="user-label">
              <span>头像</span>
            </div>
            <div class="user-info">
              <span>
                <img :src="MyUser.avatar" alt="" v-if="MyUser.avatar">
                <img src="@/assets/img/bg_avatar.png" alt="" v-else>
              </span>
            </div>
          </div>
        </div>
      </Card>
    </div>
    <!-- 编辑个人信息Modal -->
    <edit-Modal :visible="editModal" :MyUser="MyUser" @on-ok="handleEditPersonInfo" @on-cancel="handleCancel" @onPropsChange="handleChange"></edit-Modal>

    <!-- 修改密码Modal -->
    <changePSW-Modal :visible="changeModal" @on-ok="handleConfirm" @on-cancel="changeModal=false" @onPropsChange="handleChange"></changePSW-Modal>
  </div>
</template>

<script  type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import editModal from 'pages/user/user-edit';
import changePSWModal from 'pages/user/change-psw';
import { sex } from '@/filters/filters';
import accountService from '@/services/accountService';
import { mapActions, mapGetters } from 'vuex';

export default {
	data() {
		return {
			editModal: false,
			changeModal: false,
			unit: '1',
			position: '1',
			params: {
				id: '',
			},
		};
	},
	components: {
		'v-title': vTitle,
		'edit-Modal': editModal,
		'changePSW-Modal': changePSWModal,
	},
	computed: {
		...mapGetters({ MyUser: 'getUserData', userInfo: 'userInfo' }),
	},
	filters: {
		sex,
	},
	methods: {
		...mapActions(['getUserData']),
		handleEdit() {
			this.editModal = true;
		},
		handleChange(propName, newVal) {
			this[propName] = newVal;
		},
		handleChangePSW() {
			this.changeModal = true;
		},
		// 修改信息
		handleEditPersonInfo(MyUser) {
			accountService.personModify(MyUser).then(() => {
				this.getUserData(this.params);
				this.editModal = false;
				this.$Message.success('个人信息修改成功');
			});
		},
		handleCancel() {
			this.editModal = false;
		},
		handleChangePWD() {
			this.changeModal = false;
		},
		// 修改密码
		handleConfirm(data) {
			let params = {
				oldpwd: data.pwd,
				newpwd: data.newpwd,
			};
			accountService.pwdModify(params).then(() => {
				this.changeModal = false;
				this.$Message.success('请重新登录');
				localStorage.removeItem('autoLogin');
				localStorage.removeItem('userInfo');
				localStorage.removeItem('loginInfo');
				localStorage.removeItem('userPower');
				this.$router.push({ name: 'login' });
			});
		},
	},
	created() {
		this.params.id = JSON.stringify(this.userInfo.id);
		this.getUserData(this.params);
	},
};
</script>

<style lang="css" scoped>
.user-container .user-edit,
.user-container .user-adjust {
  color: #424e67;
  padding-left: 5px;
}
.user-container .user-edit {
  padding-right: 18px;
}
.user-container .user-text {
  font-size: 18px;
  color: #666;
  font-weight: 400;
}
.user-container .ivu-card,
.user-container .user-oprate {
  padding-top: 15px;
}
.user-container .user-info-box {
  position: relative;
  margin-bottom: 22px;
}
.user-container .user-label,
.user-container .user-info {
  height: 53px;
  line-height: 53px;
}
.user-container .user-label {
  float: left;
  background: #f3f5f7;
  width: 105px;
  text-align: center;
}
.user-container .user-info {
  position: absolute;
  width: calc(100% - 113px);
  margin-left: 113px;
  border-bottom: 1px solid #eee;
}
.user-container .user-info > span {
  padding-left: 80px;
}
.user-container .user-info-content {
  padding-top: 35px;
  padding-left: 85px;
  padding-bottom: 45px;
}
.user-container .user-avater {
  padding-top: 40px;
}
.user-container .user-avater .user-info > span {
  padding-left: 75px;
}
.user-container .user-avater  img {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border: 5px solid #f1ce7e;
  border-radius: 100%;
  margin-top: -55px;
}
.user-edit-contain .user-img {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border: 5px solid #f1ce7e;
  border-radius: 100%;
}
.user-edit-contain .change-avatar {
  color: #51c78a;
  text-decoration: underline;
  padding-left: 20px;
}
</style>
