<template>
  <Modal title="修改密码" :mask-closable="false" v-model="p_visible" @on-ok="ok" @on-cancel="cancel">
    <div class="user-edit-contain">
      <Form :label-width="85" :model="MyUser" :rules="ruleCustom" ref="MyUser">
        <Form-item label="原密码：" prop="pwd">
          <Input type="password" v-model.trim="MyUser.pwd"></Input>
        </Form-item>
        <Form-item label="新密码：" prop="newpwd">
          <Input type="password" v-model.trim="MyUser.newpwd"></Input>
        </Form-item>
        <Form-item label="确认密码：" prop="passwdCheck">
          <Input type="password" v-model.trim="MyUser.passwdCheck"></Input>
        </Form-item>
      </Form>
    </div>
    <div slot="footer" class="tac">
      <Button @click="cancel">取消</Button>
      <Button @click="ok('MyUser')">确定</Button>
    </div>
  </Modal>
</template>
<script type="text/ecmascript-6">
import propsync from '@/mixins/propsync';
export default {
	mixins: [propsync],
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const validatePwd = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入原密码'));
			} else {
				let obj = JSON.parse(localStorage.getItem('loginInfo'));
				if (value !== obj.pwd) {
					callback(new Error('请输入正确的密码'));
				}
				callback();
			}
		};
		const validatePass = (rule, value, callback) => {
			// let req = /^(?!\D+$)(?![^a-zA-Z]+$)\S{6,20}$/
			console.log(value.length);
			if (value === '') {
				callback(new Error('请输入新密码'));
			} else if (value.length < 6) {
				callback(new Error('密码至少大于等于6位'));
			} else if (value.length > 20) {
				callback(new Error('密码应小于20位'));
			} else {
				if (this.MyUser.passwdCheck !== '') {
					// 对第二个密码框单独验证
					this.$refs.MyUser.validateField('passwdCheck');
				}
				callback();
			}
		};
		const validatePassCheck = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.MyUser.newpwd) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			MyUser: {
				pwd: '',
				newpwd: '',
				passwdCheck: '',
			},
			ruleCustom: {
				pwd: [{ validator: validatePwd, trigger: 'blur' }],
				newpwd: [{ validator: validatePass, trigger: 'blur' }],
				passwdCheck: [{ validator: validatePassCheck, trigger: 'blur' }],
			},
		};
	},
	methods: {
		ok(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.$emit('on-ok', this.MyUser);
				}
			});
		},
		cancel() {
			this.$emit('on-cancel');
		},
		handleChange(page, index) {
			this.p_visible = index;
		},
	},
};
</script>
<style lang="css" scoped>
</style>
